
export function reinsertInArray(arr, fromIdx, toIdx) {
    if (fromIdx < 0 || fromIdx >= arr.length) {
        throw new RangeError(`fromIdx must be positive and less than the array length ${arr.length}, was ${fromIdx}`)
    }
    if (toIdx < 0 || toIdx >= arr.length) {
        throw new RangeError(`toIdx must be positive and less than the array length ${arr.length}, was ${fromIdx}`)
    }

    return [...Array(arr.length).keys()].map(i => {
        let j = i;
        if (i === toIdx) {
            j = fromIdx;
        } else {
            if (i > fromIdx || (i === fromIdx && i < toIdx)) {
                j = j + 1;
            }
            if (i > toIdx) {
                j = j - 1;
            }
        }
        return arr[j];
    });

}
