import './grippy.css';
import React from 'react';
import { Slider } from '@mui/material';
import PoppingColorPicker from './PoppingColorPicker';

class CurveSettingSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPicker: false,
            draggable: true
        }
    }

    handleSliderChange(event, newValues) {
        if (this.props.onConfidencesChanged) {
            this.props.onConfidencesChanged(this.props.curve, newValues);
        }
    }
    
    handleColorChange(color) {
        if (this.props.onColorChanged) {
            this.props.onColorChanged(this.props.curve, color);
        }
    }

    handleColorChangeComplete(color) {
        if (this.props.onColorChangedComplete) {
            this.props.onColorChangedComplete(this.props.curve, color);
        }
    }

    handleChangeShown(event) {
        if (this.props.onShowChanged) {
            this.props.onShowChanged(this.props.curve, event.target.checked);
        }
    }

    handleChangePointsShown(event) {
        if (this.props.onPointsShowChanged) {
            this.props.onPointsShowChanged(this.props.curve, event.target.checked);
        }
    }

    handlePriceChanged(event) {
        if (this.props.onPriceChanged) {
            this.props.onPriceChanged(this.props.curve, event.target.value);
        }
    }

    cancelDrag(event) {
        event.preventDefault();
    }

    handleDragOver(event) {
        event.preventDefault();
    }

    handleDrop(event) {
        let draggedIdx = parseInt(event.dataTransfer.getData("text"));
        if (this.props.onDrag) {
            this.props.onDrag(draggedIdx, this.props.idx);
        }
    }

    handleDrag(event) {
        event.dataTransfer.clearData();
        event.dataTransfer.setData("text/plain", this.props.idx);
    }

    disableDrag(event) {
        this.setState({
            "draggable": false
        });
    }

    enableDrag(event) {
        this.setState({
            "draggable": true
        });
    }

    handleMouseEnter(event) {
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(this.props.curve.getId());
        }
    }

    handleMouseLeave(event) {
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave(this.props.curve.getId());
        }
    }

    render() {
        const curve = this.props.curve;
        const confidences = curve.getAvailableConfidenceLevels().sort();
        const minConfidence = confidences[0];
        const maxConfidence = confidences[confidences.length - 1];

        return <div
                class="CurveSelector"
                id={ curve.getId() }
                // onDragEnd={ this.props.onDrag }
                onDragOver={ this.handleDragOver.bind(this) }
                onDrop={ this.handleDrop.bind(this) }
                onDragStart={ this.handleDrag.bind(this) }
                draggable={ this.state.draggable }
                onMouseEnter={ this.handleMouseEnter.bind(this) }
                onMouseLeave={ this.handleMouseLeave.bind(this) }
            >
            <div class="CurveSelectorLabel">
                <span
                    class="grippy"
                >    
                </span>
                <input
                    type="checkbox"
                    name={ this.props.id }
                    checked={ this.props.shown }
                    onChange={ this.handleChangeShown.bind(this) }
                />
                <label>
                    { this.props.label }
                </label>
                <PoppingColorPicker
                    color={ this.props.color }
                    onChange={ this.handleColorChange.bind(this) }
                    onChangeComplete={ this.handleColorChangeComplete.bind(this) }
                />
            </div>
            <div class="Slider">
                <Slider
                    size="small"
                    min={ minConfidence }
                    max={ maxConfidence }
                    marks={ true }
                    // marks={ [ .25, .5, .75, .95 ].map(i => { return {value: i, label: i}; }) }
                    // marks={ curve.getAvailableConfidenceLevels().map(i => {return {value: i, label: i };}) }
                    step={0.05}
                    value={ this.props.sliderValues }
                    onChange={ this.handleSliderChange.bind(this) } 
                    valueLabelDisplay="auto"
                />
            </div>
            <div class="CurveSelectorRawDataCheckbox">
                <input
                    type="checkbox"
                    checked={ this.props.pointsShown }
                    onChange={ this.handleChangePointsShown.bind(this) }
                />
                <label>
                    Raw data
                </label>
            </div>
            <div class="CurveSelectorPriceBox">
                <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={ this.props.price }
                    onChange={ this.handlePriceChanged.bind(this) }
                    class={ this.props.priceIsOld ? "OldPrice" : "CurrentPrice" }
                    onMouseEnter={ this.disableDrag.bind(this) }
                    onMouseLeave={ this.enableDrag.bind(this) }
                />
            </div>
            <div class="CurveSelectorTDP">
                { curve.getDefaultPower() }
            </div>
        </div>;
    }
}

export default CurveSettingSelector;
