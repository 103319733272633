import './App.css';
import React from 'react';
import CurveSettingSelector from './CurveSettingSelector';
import CPUPowerScalingCurveData from './CPUPowerScalingCurveData';
import PowerScalingCurvePlot from './PowerScalingCurvePlot';
import { reinsertInArray } from './Util';
import fetch from 'cross-fetch';
import config from './config.json';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

const defaultColors = [
    { r: 31, g: 119, b: 180, a: 0.1 },
    { r: 255, g: 127, b: 14, a: 0.1 },
    { r: 44, g: 160, b: 44, a: 0.1 },
    { r: 214, g: 39, b: 40, a: 0.1 },
    { r: 148, g: 103, b: 189, a: 0.1 },
    { r: 140, g: 86, b: 75, a: 0.1 },
    { r: 227, g: 119, b: 194, a: 0.1 },
    { r: 127, g: 127, b: 127, a: 0.1 },
    { r: 188, g: 189, b: 34, a: 0.1 },
    { r: 23, g: 190, b: 207, a: 0.1 },
];

const highlightColors = {
    highlighted: { r: 188, g: 189, b: 34, a: 0.1 },
    background: { r: 31, g: 119, b: 180, a: 0.1 },
};

function adjustColorAlpha(color, newAlpha) {
    return {
        r: color.r,
        g: color.g,
        b: color.b,
        a: newAlpha
    };
}

function colorScaleFromAlphaGradient(color, alphaGradient, alphaMultiplier = 1.) {
    let length = alphaGradient.length;
    let colorscale = Array.from(alphaGradient).map((alpha, i) =>
        [
            i/length,
            adjustColorAlpha(color, alpha * alphaMultiplier)
        ]
    );
    colorscale.push([
        1., colorscale[length - 1][1]
    ]);
    return colorscale;
}

const twoWeeksDiff = 14 * 24 * 60 * 60 * 1000;


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curves: {},
            plotData: {},
            plotMode: 'perf',
            selectorOrder: [],
            highlightedCurve: null
        }
    }

    componentDidMount() {
        fetch(config.metaDataPath)
        .then(res => {
            if (res.ok) {
                return res.json();
            }
            throw res;
        })
        .then(cpu_curve_meta => {
            let loadingPromises = cpu_curve_meta.map(curveMetaData => CPUPowerScalingCurveData.load(curveMetaData));

            return Promise.all(loadingPromises);
        })
        .then(loadedCurves => { 
                let newCurves = { ...this.state.curves };
                let plotData = { ...this.state.plotData };

                for (const [i, curve] of loadedCurves.entries()) {
                    newCurves[curve.getId()] = curve;    

                    const confidenceLevels = curve.getAvailableConfidenceLevels().sort();
                    const lowestConfidence = confidenceLevels[0];
                    const highestConfidence = confidenceLevels[confidenceLevels.length - 1];

                    plotData[curve.getId()] = {
                        show: curve.shouldShowAtStart(),
                        confidenceRange: [ lowestConfidence, Math.min(.75, highestConfidence) ],
                        color: defaultColors[i % defaultColors.length],
                        showPoints: false,
                        price: null,
                    };
                }

                let selectorOrder = loadedCurves.map(curve => curve.getId());

                this.setState({
                    curves: newCurves,
                    plotData: plotData,
                    selectorOrder: selectorOrder,
                });

                // initial loading done, now fetch price data
                return fetch(config.priceDataPath);
            },
            reason => console.log(reason)
        )
        .then(res => {
            if (res.ok) {
                return res.json();
            }
            throw res;
        })
        .then(pricesData => {
            let plotData = { ...this.state.plotData };

            for (const [id, priceData] of Object.entries(pricesData)) {
                plotData[id]["price"] = priceData["price"];
                plotData[id]["priceDate"] = Date.parse(priceData["date"]);
            }

            this.setState({
                plotData: plotData
            });
        })
        .catch(err => {
            console.log("unable to load cpu data");
            console.log(err);
        });
    }

    handleChange(color) {
        this.setState({ color: color.rgb });
    }

    makeCPUPlotData(curve) {
        const curvePlotData = this.state.plotData[curve.getId()];
        const confidences = curvePlotData.confidenceRange;
        const includePrice = this.state.plotMode === 'perf/price';
        const priceMultiplier = includePrice ? 
            ((curvePlotData.price !== null) ? 1./curvePlotData.price : Infinity) :
            1.0;

        const color = this.state.highlightedCurve ? 
            ((this.state.highlightedCurve === curve.getId()) ? highlightColors.highlighted : highlightColors.background) :
            curvePlotData.color;

        let traces = [];
        if (curvePlotData.showPoints) {
            const points = curve.getPoints(priceMultiplier);
            let pointsTrace = {
                x: points.x.data,
                y: points.y.data,
                type: 'scatter',
                mode: 'markers',
                marker: {
                    color: adjustColorAlpha(color, .9),
                },
                name: `${curve.getLabel()} | Observation`,
            };
            traces = [pointsTrace];
        }
        
        let defaultPowerPoint = {
            x: [curve.getDefaultPower()],
            y: [0.],
            type: 'scatter',
            mode: 'markers',
            marker: {
                color: adjustColorAlpha(color, .9),
            },
            name: `${curve.getLabel()} | Default Power`,
        };
        traces.push(defaultPowerPoint);

        let colorscale = colorScaleFromAlphaGradient(color, curve.nonzeroRatios.data, curvePlotData.color.a);

        return traces.concat(curve.getCurves(confidences[0], confidences[1], priceMultiplier).map((c, i) => {
            return {
                x: c.x,
                y: c.y,
                type: 'scatter',
                mode: 'none',
                fill: 'toself',
                fillgradient: {
                    type: "horizontal",
                    colorscale: colorscale,
                },
                hoverinfo: 'points+fills',
                name: `${curve.getLabel()} | ${Math.round(100 * c.confidence)} %`,
            }
        }));

    }

    updatePlotData(curveId, newData) {
        let plotData = { ...this.state.plotData };

        let curvePlotData = plotData[curveId];
        for (let k in newData) {
            curvePlotData[k] = newData[k];
        }

        this.setState({ plotData: plotData });
    }

    handleCurveShowChanged(curve, newValue) {
        this.updatePlotData(curve.getId(), { show: newValue });
    }

    handleCurveShowPointsChanged(curve, newValue) {
        this.updatePlotData(curve.getId(), { showPoints: newValue });
    }

    handleCurveConfidencesChanged(curve, confidences) {
        this.updatePlotData(curve.getId(), { confidenceRange: confidences });
    }

    handleCurveColorChanged(curve, color) {
        this.updatePlotData(curve.getId(), { color: color.rgb });
    }

    handleDrag(from, to) {
        let selectorOrder = this.state.selectorOrder;
        
        let newSelectorOrder = reinsertInArray(selectorOrder, from, to);

        this.setState({
            selectorOrder: newSelectorOrder
        });
    }

    handleCurveSelectorMouseEnter(curveId) {
        this.setState({
            highlightedCurve: curveId,
        });
    }

    handleCurveSelectorMouseLeave(curveId) {
        this.setState({
            highlightedCurve: null,
        });
    }

    handlePriceChanged(curve, newValue) {
        let parsedValue = parseFloat(newValue);
        if (isNaN(parsedValue) || !isFinite(parsedValue)) {
            parsedValue = null;
        }
        this.updatePlotData(curve.getId(), {
            price: parsedValue,
            priceDate: Date.now()
        });
    }

    handlePlotModeChanged(newMode) {
        this.setState({
            plotMode: newMode,
        });
    }

    render() {
        let plotOrder = this.state.selectorOrder
            .filter(id => (this.state.plotData[id].show && this.state.highlightedCurve !== id));

        if (this.state.highlightedCurve) {
            plotOrder.unshift(this.state.highlightedCurve);
        }
        
        const plotCurves = plotOrder
            .reverse()
            .map(id => this.makeCPUPlotData(this.state.curves[id]))
            .flat();

        return (
            <div class="App">
                <div class="Text">
                    <h1>CPU Power Scaling Performance Comparison</h1>
                    <p>
                        Recent CPU generations launch with ever higher power targets. For consumers that aim to optimize power consumption,
                        here are <b>prediction curves for the performance/watt trade-off</b> curve of selected CPU models.
                    </p>
                    <p>
                        For each CPU model, <a href="#plot">the plot below</a> shows a graph visualizing the confidence intervals of performance
                        for each power target level (along the x-axis). By default the plot shows the performance range
                        achieved with a confidence of 75%, i.e., in three out of four CPUs of that model a performance value
                        within that range should be achieved. A <a href="#model">detailed description of the prediction model</a> used can be found
                        below the plot.
                    </p>
                    <h2>How to Use</h2>
                    <p>
                        <span>In the list below, <b><i>tick the CPU models</i></b> whose performance curve to <b>display in the plot</b>.</span>
                        <span> <b>Change the color</b> of the corresponding graph <b><i>by clicking on the colored rectangle</i></b> behind to open a color picker.</span>
                        <span> <b><i>Adjust the slider</i></b> to <b>change the confidence levels to display</b> for the corresponding CPU model.</span>
                        <span> To <i>change the order in which graphs are drawn</i> you can <b>drag and drop items in the list</b> to different positions. The CPU model highest in the list will be draw over all others.</span>
                        <span> You can also <b>display the raw performance measurements</b> used to train the prediction model by <b><i>ticking the "Raw data" box</i></b>.</span>
                        <span> <b><i>Use the controls in the top right of the plot</i></b> to <b>pan, zoom, or scale</b> the plot.</span> 
                    </p>
                    <p>
                        <span>Instead of raw performance, you can also <b>plot the performance per price</b> by switching the plotting mode in the <b><i>dropdown menu below the plot</i></b>.</span>
                        <span> This divides the curves by the price given for each CPU. The default prices are updated on a best-effort basis but may be out of date.</span>
                        <span> The prices are shown <i>greyed-out if the price information is older than two weeks</i>.</span>
                        <span> You can <b>update pricing information</b> for your own use by manually <b><i>setting them in the corresponding input fields</i></b>.</span>
                    </p>
                    <h2><a id="plot" href="#plot">Plot</a></h2>
                </div>
                    <div id="PlotAndList">
                    <div id="CurveSelectors">
                        <div class="CurveSelector CurveSelectorHeader">
                            <div class="CurveSelectorLabel">CPU</div>
                            <div class="Slider">Confidence Interval</div>
                            <div class="CurveSelectorRawDataCheckbox"></div>
                            <div class="CurveSelectorPriceBox" title="The current price [US$ by default].">Price</div>
                            <div class="CurveSelectorTDP" title="The manufacturer's design power [Watt].">TDP</div>
                        </div>
                        {
                            this.state.selectorOrder.map((id, i) => {
                                const curve = this.state.curves[id];
                                const plotData = this.state.plotData[id];
                                return <CurveSettingSelector
                                    idx={ i }
                                    label={ curve.getLabel() }
                                    key={ id }
                                    curve={ curve }
                                    shown={ plotData.show }
                                    pointsShowns={ plotData.showPoints }
                                    color={ plotData.color }
                                    sliderValues={ plotData.confidenceRange }
                                    price={ (plotData.price === null) ? '' : plotData.price }
                                    priceIsOld={ (plotData.priceDate <= Date.now() - twoWeeksDiff) }
                                    onConfidencesChanged={ this.handleCurveConfidencesChanged.bind(this) }
                                    onShowChanged={ this.handleCurveShowChanged.bind(this) }
                                    onPointsShowChanged={ this.handleCurveShowPointsChanged.bind(this) }
                                    onColorChanged={ this.handleCurveColorChanged.bind(this) }
                                    onDrag={ this.handleDrag.bind(this) }
                                    onPriceChanged={ this.handlePriceChanged.bind(this) }
                                    onMouseEnter={ this.handleCurveSelectorMouseEnter.bind(this) }
                                    onMouseLeave={ this.handleCurveSelectorMouseLeave.bind(this) }
                                />;
                            })
                        }
                    </div>
                    <PowerScalingCurvePlot
                        curves={ plotCurves }
                        xrange={ [ 0, config.xAxisLimit] }
                        yAxisLabel={ config.yAxisLabel[this.state.plotMode] }
                        mode={ this.state.plotMode } 
                        onModeChange={ this.handlePlotModeChanged.bind(this) }
                    />
                </div>
                <div class="Text">
                    <h2><a id="model" href="#model">The Predictive Model</a></h2>
                        <p>
                            To arrive at the plot above, we follow a <a href="https://en.wikipedia.org/wiki/Bayesian_inference">Bayesian learning</a> approach
                            in which we first use prior knowledge and reasonable assumption to formulate a model and then
                            use actual observations (/data) to refine it.
                        </p>
                        <p>
                            We assume that a CPU power scaling curve is <b>monotonously increasing</b> and <b>concave</b>, i.e.,
                            a higher power level will result in higher performance scores but there are diminishing returns. Therefore
                            we model the power scaling curve as the following exponential function
                            <MathJaxContext><MathJax class="Equation">{String.raw`\( f(x) = d + c e^{a x} \),`}</MathJax></MathJaxContext>
                            with parameters <MathJaxContext><MathJax inline={ true } >{String.raw`\( a < 0 \)`}</MathJax></MathJaxContext> and <MathJaxContext><MathJax inline={ true } >{String.raw`\( c < 0 \)`}</MathJax></MathJaxContext> determining
                            the shape of the curve and <MathJaxContext><MathJax inline={ true } >{String.raw`\( d \)`}</MathJax></MathJaxContext> giving the upper limit to performance.
                        </p>
                        <p>
                            Additionally we assume that a CPU may not run at all for low power levels without performance smoothly decreasing to zero.
                            Thus the model assumes an additional <i>cutoff threshold</i> <MathJaxContext><MathJax inline={ true } >{String.raw`\( t \)`}</MathJax></MathJaxContext> { /*<MathComponent tex={String.raw`t`} display={ false } />*/ } below
                            which the value is always zero, i.e.,
                            <MathJaxContext>
                                <MathJax class="Equation">{String.raw`\(
                                    f(x) = \left\{\begin{array}{rl}
                                        d + c e^{a x} & \text{, if } x > t \\
                                        0 & \text{, if } x \leq t.
                                    \end{array}\right. 
                                \)`}
                            </MathJax>
                            </MathJaxContext>
                        </p>
                        <p>
                            We apply prior distributions on the parameters that result in the following distribution for power scaling curves.
                            The plot shows the region in which 95% of CPU power scaling curves would lie according to our prior assumptions,
                            with less transparent regions corresponding to higher likelihoods of observing a curve in that area. Additionally
                            the plot also shows 10 random realizations of potential curves (as blue lines) according to the model. As we can see,
                            our prior assumptions allow for a large variety of possible curves within the constraints of being increasing and concave
                            and having reasonable upper limits, suggesting that it will be able to reliably identify curves corresponding to actual
                            performance measurements.
                        </p>
                        <img
                            id="PriorPlot"
                            src="model_prior.svg"
                            alt="The likelihood regions of power scaling curves according to the modelling assumptions."
                        />

                    <h3><a id="training" href="#training">Inference / Training</a></h3>
                        <p>
                            We learn the posterior distribution of our model after making actual performance measurements
                            using <a href="https://en.wikipedia.org/wiki/Markov_chain_Monte_Carlo">Markov chain Monte Carlo</a>. We rely
                            on the <a href="https://github.com/pyro-ppl/numpyro#readme">NumPyro</a> probabilistic programming language
                            for actual implementation of the method.
                        </p>
                    <h3><a id="limitations" href="#limitations">Limitations</a></h3>
                        <p>
                            Please be aware of the following limitation when using the results of the model:
                        </p>
                        <ul>
                            <li>
                                We currently do not model a maximum power level and allow the model to freely extrapolate beyond the last known
                                measurement. As a result the plot is likely inaccurate for high power levels.
                            </li>
                        </ul>
                </div>
                <div class="Text">
                    <h2><a id="data" href="#data">Data</a></h2>
                    <p>
                        We use performance data measured and published by <a href="https://fullysilentpcs.com/" target="_blank" rel="noreferrer" >Fully Silent PCs</a> on
                        their <a href="https://www.youtube.com/@fullysilentpcs/" target="_blank" rel="noreferrer" >YouTube channel</a>. Particularly, we obtained
                        the data points from these two videos:
                    </p>
                    <ul>
                        <li><a href="https://www.youtube.com/watch?v=tP6rHoKtK9U" target="_blank" rel="noreferrer" >CPU Power Limits vs. Performance - AMD Ryzen 7000 Zen 4 Raphael</a>,</li>
                        <li><a href="https://www.youtube.com/watch?v=ZJQ1MgwgXlM" target="_blank" rel="noreferrer" >CPU Power Limits vs. Performance - Vermeer, Cezanne, and Alder Lake</a>.</li>
                    </ul>
                    <p>
                        You can download a <a href="./cpu_power_scaling_data.csv">csv file containing the raw data points here</a>.
                    </p>
                    <p>
                        Unfortunately we are currently unable to run our own benchmarks on a large number of current hardware. <b>If you are able to contribute
                        data points</b> for CPU models missing so far or for different benchmarks, please contact us a <a href="mailto:contact@cpupowerscaling.info">contact@cpupowerscaling.info</a>.
                    </p>
                    <h2><a id="future" href="#future">Potential Future Updates</a></h2>
                    <ul>
                        <li>Ranking of CPUs for a user defined power target</li> 
                        <li>Model cutoff at high power levels</li>
                        <li>Correlating performance across different benchmarks</li>
                    </ul>
                </div>
                <div class="Disclaimer">
                    The website icon is based on an image from user D-Kuru
                    at <a href="https://commons.wikimedia.org/wiki/File:AMD_Phenom_II_X4_840_%28HDX840WFK42GM%29_CPU-top_oblique_PNr%C2%B00373.jpg" target="_blank" rel="noreferrer">Wikimedia</a> and
                    can be freely reused under the terms of
                    the <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" rel="noreferrer">CC BY-SA 4.0 license</a>.
                </div>
            </div>
        );
    }
}

export default App;
