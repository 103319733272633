import React from 'react';
import { SketchPicker } from 'react-color';
import Popover from '@mui/material/Popover';

class PoppingColorPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showPicker: false,
            pickerAnchor: null,
        }
    }

    handleColorPickerButtonClick(event) {
        this.setState({
            showPicker: !this.state.showPicker,
            pickerAnchor: event.currentTarget,
        })
    }

    handleColorPickerCloseClick() {
        this.setState({
            showPicker: false,
            pickerAnchor: null,
        });
    }

    render() {
        const triggerStyle = {
            width: '20px',
            height: '20px',
            background: `rgba(${ this.props.color.r }, ${ this.props.color.g }, ${ this.props.color.b }, ${ this.props.color.a })`,
        };

        return <div class="PoppingColorPickerTrigger">
            <div
                style={ triggerStyle }
                onClick={ this.handleColorPickerButtonClick.bind(this) }
            />
            <Popover
                open={ this.state.showPicker }
                anchorEl={ this.state.pickerAnchor }
                onClose={ this.handleColorPickerCloseClick.bind(this) }
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <SketchPicker
                    color={ this.props.color }
                    onChange={ this.props.onChange }
                    onChangeComplete={ this.props.onChangeComplete } 
                />
            </Popover>
        </div>
    }
}


export default PoppingColorPicker;
