import './App.css';
import React from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js/lib/core';
import PlotlyScatter from 'plotly.js/lib/scatter';

Plotly.register(PlotlyScatter);
const Plot = createPlotlyComponent(Plotly);

class PowerScalingCurvePlot extends React.Component {

    handleModeChange(event) {
        if (this.props.onModeChange) {
            this.props.onModeChange(event.target.value);
        }
    }

    render() {
        return (
            <div>
                <Plot

                    data = { this.props.curves }

                    layout = {{
                        autosize: true,
                        title: 'CPU Power Scaling',
                        spikedistance: -1,
                        hovermode: 'closest',
                        showlegend: false,
                        uirevision: true,
                        xaxis: {
                            showspikes: true,
                            spikemode: 'across',
                            spikecolor: '#000000',
                            spikesnap: 'cursor',
                            spikethickness: 1,
                            spikedash: 'solid',
                            title: 'CPU Power Limit [W]',
                            range: this.props.xrange,
                        },
                        yaxis: {
                            showspikes: true,
                            spikemode: 'across',
                            spikecolor: 'black',
                            spikesnap: 'cursor',
                            spikethickness: 1,
                            spikedash: 'solid',
                            title: this.props.yAxisLabel,
                            range: this.props.yrange,
                        },
                    }}

                    divId="Plot"

                />
                <div style={{"textAlign": "center"}}>
                    <label>Show</label>
                    <select onChange={ this.handleModeChange.bind(this) } value={ this.props.mode } style={{"marginLeft": "10px"}}>
                        <option value="perf">Performance</option>
                        <option value="perf/price">Performance/Price</option>
                    </select>
                </div>
            </div>
        );
    }
}

export default PowerScalingCurvePlot;
